import React from 'react';
import {Button} from '../primitives/Button';
import {twMerge} from 'tailwind-merge';

interface Props {
  checkoutUrl: string;
  className?: string;
}

const Checkout = ({checkoutUrl, className}: Props) => {
  return (
    <Button
      mode="ruby"
      className={twMerge('w-full', className)}
      href={checkoutUrl ? checkoutUrl : ''}
      disabled={!checkoutUrl}
    >
      Checkout
    </Button>
  );
};

export default Checkout;
